<template>
    <section class="iq-section iq-team">
        <header class="iq-team__header"></header>
        <section class="iq-team__info">
            <div class="iq-team__pics">
                <img :src="$store.getters.userinfo.avatar" alt="">
            </div>
            <h4>{{$store.getters.userinfo.nickName}}</h4>
            <div class="iq-team__desc">
                <div class="iq-team__desc-item">
                    <van-popover v-model="showPopover" theme="dark" trigger="click">
                        <p class="iq-team__desc-tips">您当前为小团长，下下级的收益暂存，您发展20位团队成员，成为大团长后即可提现。</p>
                        <template #reference>
                            <div class="span">当前团队收益<van-icon name="question" v-if="$store.getters.userinfo.userLevel <= 1"/></div>
                            <!--  -->
                            <strong>￥{{totalToWithdrawalAmout}}</strong>
                        </template>
                    </van-popover>
                </div>
                <div class="iq-team__desc-item">
                    <div class="span">当前团队人数</div>
                    <strong>{{memberNum}}</strong>
                </div>
            </div>
        </section>
<!--        <section class="iq-team__control">-->
<!--            <div class="iq-team__type"></div>-->
<!--            <a>升级后可提现说明</a>-->
<!--        </section>-->
        <van-sticky>
            <div class="iq-team__th">
                <div class="iq-team__item">
                    <div class="iq-team__item-name">姓名</div>
                    <div class="iq-team__item-tags">身份</div>
                    <div class="iq-team__item-nums">收益</div>
                    <div class="iq-team__item-time">日期</div>
                </div>
            </div>
        </van-sticky>
        <section class="iq-team__list">
<!--            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
<!--                <van-list-->
<!--                        v-model="loading"-->
<!--                        :finished="finished"-->
<!--                        finished-text="没有更多了"-->
<!--                        @load="onLoad"-->
<!--                >-->
                    <div class="iq-team__item" v-for="(item, $index) in list" :key="$index">
                        <div class="iq-team__item-name">
                            <img :src="item.avatar" alt="">
                            <span class="van-ellipsis" style="max-width: 85px;">{{ item.name }}</span>
                        </div>
                        <div class="iq-team__item-tags">
                            <van-button plain hairline type="primary" size="mini">
                                {{item.level == 1 ? '小团长' : (item.level == 2 ? '大团长' : '会员')}}
                            </van-button>
                        </div>
                        <div class="iq-team__item-nums">￥{{item.contribution}}</div>
                        <div class="iq-team__item-time">{{item.bindTime}}</div>
                    </div>
<!--                </van-list>-->
<!--            </van-pull-refresh>-->
        </section>
        <!-- actionsheet -->
        <van-action-sheet cancel-text="取消" v-model="classifyShow" :actions="classify"/>
    </section>
</template>

<script>
  import {getTixian} from "@/api/user";

  export default {
    name: 'MyTeam',
    data () {
      return {
        classify: [{name: '全部'}, {name: '团长'}, {name: '会员'}],
        classifyShow: false,
        pageNum: 1,
        pages: 1,
        list: [],
        refreshing: false,
        loading: false,
        finished: false,
        totalToWithdrawalAmout: 0,
        memberNum: 0,
        showPopover: false
      }
    },
    mounted () {

    },
    methods: {
      onRefresh() {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      onLoad() {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.pages = 1;
          this.refreshing = false;
        }
        this.loadList();
      },
      loadList() {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return;
        }
        this.loading = true;
        getTixian({
          pageNum: this.pageNum,
        }).then(data => {
          if (data.success == true) {
            this.list = data.data.list;
            this.memberNum = data.data.memberNum;
            this.totalToWithdrawalAmout = data.data.totalToWithdrawalAmout;
          } else {
            this.finished = true;
          }
        }).finally(() => {
          this.loading = false
        }).catch(() => {
          this.finished = true;
        })
      }
    },
    created () {
      this.loadList();
    }
  }
</script>

<style lang="scss" scoped>
    .iq-team {
        height: 100%;
        overflow: scroll;
        background: #f7f7f7;

        &__ {

            &header {
                position: relative;
                height: 100px;
                padding: 0 20px;
                background: #dc5752;
                color: #fff;
            }

            &info {
                position: relative;
                z-index: 2;
                width: calc(100% - 40px);
                height: 70px;
                margin: -40px auto 5px;
                padding: 0 20px 0 0;
                display: flex;
                align-items: center;
                border-radius: 5px;
                box-shadow: 0 0 25px 2px rgba(0, 0, 0, .2);
                background: #fff;
                box-sizing: border-box;

                & > h4 {
                    position: absolute;
                    top: -40px;
                    left: 100px;
                    line-height: 40px;
                    color: #fff;
                    font-size: 16px;
                }
            }

            &desc {
                flex: 1;
                height: 100%;
                text-align: center;
                font-size: 0;

                &-tips {
                    width: 120px;
                    padding: 10px;
                    font-size: 12px;
                }

                &-item {
                    display: inline-block;
                    vertical-align: middle;
                    // font-size: 0;
                    width: 50%;
                }

                .span,
                strong {
                    display: block;
                    line-height: 1;
                }

                .span {
                    position: relative;
                    padding: 18px 15px 10px 0;
                    font-size: 14px;

                    ::v-deep .van-icon {
                        position: absolute;
                        top: 18px;
                        right: 0;
                    }
                }

                strong {
                    font-size: 14px;
                    color: #dc5752;
                }
            }

            &pics {
                margin-right: 15px;

                img {
                    display: block;
                    margin-top: -80px;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background: #fff;
                    border: 2px solid #fff;
                }
            }

            &control {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 40px;
                padding: 0 20px;
                border-bottom: 1px solid #c9c9c9;
                box-sizing: border-box;

                a,
                & > div {
                    position: relative;
                    font-size: 12px;

                    /*&:after {*/
                    /*    content: '';*/
                    /*    position: absolute;*/
                    /*    right: 0;*/
                    /*    width: 4px;*/
                    /*    height: 4px;*/
                    /*    border: 1px solid;*/
                    /*}*/
                }

                a {
                    color: #dc5752;
                    padding-right: 10px;

                    &:after {
                        top: 6px;
                        border-color: #fff #dc5752 #dc5752 #fff;
                        transform: rotate(-45deg);
                    }
                }

                & > div {
                    display: inline-block;
                    color: #333;
                    padding-right: 15px;

                    /*&:after {*/
                    /*    top: 4px;*/
                    /*    border-color: #fff #333 #333 #fff;*/
                    /*    transform: rotate(45deg);*/
                    /*}*/
                }
            }

            &list {
                padding: 0 20px 15px 20px;
            }

            &th {
                height: 40px;
                line-height: 40px;
                padding: 0 20px;
                background: #fff;

                .iq-team__item {
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
                }
            }

            &item {
                display: flex;
                align-items: center;
                background: #fff;
                margin-top: 15px;
                font-size: 12px;
                box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);

                &- {

                    &tags {
                        width: 50px;
                        padding: 0 5px;
                    }

                    &nums {
                        width: 50px;
                        padding: 0 5px;
                    }

                    &time {
                        width: 68px;
                        text-align: right;
                        padding-right: 10px;
                    }

                    &name {
                        flex: 1;

                        img,
                        span {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        img {
                            width: 44px;
                            height: 44px;
                            margin-right: 6px;
                        }

                        // span {
                        //   font-size: 12px;
                        // }
                    }
                }
            }
        }
    }
</style>
